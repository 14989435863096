import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ukata-tts-voice-picker',
  templateUrl: './tts-voice-picker.component.html',
  styleUrls: ['./tts-voice-picker.component.scss'],
})
export class TtsVoicePickerComponent {
  voices: string[] = [
    'en-US-AvaMultilingualNeural',
    'en-US-AnaNeural',
    'en-US-BrianMultilingualNeural',
    'en-US-EmmaMultilingualNeural',
    'en-GB-MaisieNeural',
    'es-ES-ElviraNeural',
    'fr-FR-DeniseNeural',
    'fr-FR-HenriNeural',
    'es-ES-AlvaroNeural',
    'es-ES-ArnauNeural',
  ];

  @Input()
  voice: string = '';

  @Input()
  label: string = 'Speaker';
  @Output()
  onVoiceChange = new EventEmitter<string>();

  updateVoice($event: string) {
    this.voice = $event;
    this.onVoiceChange.emit(this.voice);
  }
}
