
<nz-form-label>{{ label }}</nz-form-label>
<nz-form-item>
  <nz-select
      [nzShowSearch]="true"
      [nzAllowClear]="true"
      (ngModelChange)="updateVoice($event)"
      [(ngModel)]="voice"
  >
    <nz-option
        *ngFor="let style of voices"
        [nzLabel]="style"
        [nzValue]="style"
    ></nz-option>
  </nz-select>
</nz-form-item>
